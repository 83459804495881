<template>
  <div class="err-word">
    <div class="m-b-12" style="color: #999;">
      添加的错词将会被提示在自定义分类中，并给出您添加的校对建议。
    </div>
    <div class="query flex-c m-b-12">
      <el-input v-model="keyWord" @change="query" placeholder="按回车键搜索"></el-input>
      <div class="m-l-24 btn d" @click="openAdd">添加错词</div>
      <div class="m-l-24 btn s" @click="delKeyWord">多选删除</div>
      <div class="m-l-24 btn s" @click="clearKeyWord">清空词库</div>
    </div>
    <el-table v-loading="listLoading" class="m-t" stripe :data="tableData" :header-cell-style="{
    'text-align': 'center',
    background: '#F6F7FA',
    color: '#333',
  }" :cell-style="{ 'text-align': 'center' }" style="
    width: 100%;
    text-align: center;
    border: 0.0625rem solid #e4e6ec;
    border-bottom: none;
  " @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="inaccuracyWord" label="错词">
      </el-table-column>
      <el-table-column prop="correctWord" label="建议修改为">
      </el-table-column>
      <el-table-column prop="createTime" label="最后修改时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link type="danger" :underline="false" @click="tableDel(scope.row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :pageNumber="page.pageNumber" :pageSize="page.pageSize" :total="total" @SizeChange="SizeChange" @CurrentChange="CurrentChange"></Pagination>
    <!-- 新增错词 -->
    <el-dialog :visible.sync="addKeyWordVisible" width="30%">
      <div slot="title" class="dialog-header">
        <span class="el-dialog__title">新增错词</span>
      </div>
      <div class="dialog-body">
        <div class="flex-c add-head m-b-16" @click="addWordListFn">
          <img src="@/assets/img/gjh/jiahao.png">
          添加错词
        </div>
        <div class="add-box flex-c m-b-16" v-for="item,index in addWordList" :key="index">
          <el-input v-model="item.errWord" placeholder="错词" size="mini" clearable class=" m-r-36"></el-input>
          <el-input v-model="item.editWord" placeholder="建议修改为" size="mini" clearable class=" m-r-36"></el-input>
          <i @click="delWordListFn(item)" class="el-icon-close cur" style="color:#f58030"></i>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addKeyWordVisible= false">取 消</el-button>
        <el-button type="primary" @click="addSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/pagination.vue";
import {
  getList, addKeyWord, delKeyWord,clearKeyWord
} from "@/api/myKeyWord/index";
export default {
  components: {
    Pagination
  },
  data() {
    return {
      keyWord: '',
      listLoading: false,
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10
      },
      total: 0,
      addKeyWordVisible: false,
      addWordList: [
        { errWord: '', editWord: '' }
      ],
      selectDle: [],//选中删除
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query(){
      this.page.pageNo = 1
      this.getList()
    },
    async getList() {
      let data = {
        limit: this.page.pageSize,
        page: this.page.pageNo,
        word: this.keyWord,
        type:1
      }
      this.listLoading = true
      let res = await getList(data)
      if (res.data.code == 200) {
        this.tableData = res.data.data.list
        this.total = res.data.data.totalCount * 1
        this.listLoading = false
      }
    },
    openAdd() {
      this.addKeyWordVisible = true
      this.addWordList = [{ errWord: '', editWord: '' }]
    },
    addWordListFn() {
      this.addWordList.unshift({ errWord: '', editWord: '' })
    },
    delWordListFn(item) {
      this.addWordList.forEach((v, i) => {
        if (v.errWord == item.errWord && v.editWord == item.editWord) {
          this.addWordList.splice(i, 1)
          return
        }
      })
    },
    async addSubmit() {
      console.log(this.addWordList);
      let data = this.addWordList.map(v => {
        return {
          orgId: JSON.parse(localStorage.getItem('user')).orgId,
          type: 1,//错误词类型（1.错误词，2敏感词）
          inaccuracyWord: v.errWord,//错误词
          correctWord: v.editWord,//建议更正
        }
      })
      let res = await addKeyWord(data)
      if (res.data.code == 200) {
        this.$message.success('添加成功')
        this.getList()
      }
      this.addKeyWordVisible = false
    },
    // 收集删除数据id
    tableDel(row) {
      this.selectDle = [row.id]
      this.delKeyWord()
    },
    handleSelectionChange(val) {
      console.log(val);
      this.selectDle = val.map(v => {
        return v.id
      })
    },
    async delKeyWord() {
      if (this.selectDle.length == 0) return this.$message.warning('请选择要删除的选项')
      this.$message.warning('删除中...')
      let res = await delKeyWord({ids:this.selectDle})
      if (res.data.code == 200) {
        this.$message.success('删除成功')
        this.getList()
      }
    },
    async clearKeyWord(){
      let data = {
        type:1,
        orgId: JSON.parse(localStorage.getItem('user')).orgId,
      }
      let res = await clearKeyWord(data)
      if(res.data.code==200){
        this.$message.success('词库已清空')
        this.getList()
      }
    },
    SizeChange(val) { 
      this.page.pageSize = val
      this.getList()
    },
    CurrentChange(val) { 
      this.page.pageNo = val
      this.getList()
    },
  }
}
</script>

<style lang="scss" scoped>
.err-word {
  .query {
    height: 36px;
    .el-input {
      width: 240px;
    }
    .btn {
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    .d {
      background: #fff1e8;
      color: #ed731f;
    }
    .s {
      color: #ea3342;
      background: #fff2f3;
    }
  }
}
.add-head {
  color: #f58030;
  font-size: 16px;
  cursor: pointer;
}
.add-box {
  .el-input {
    width: 180px;
  }
}
::v-deep .el-table__body tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

::v-deep .el-table__body tbody tr:nth-child(even) td {
  background-color: #f6f7fa;
}
</style>